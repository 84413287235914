<ng-template #formError>
  <span
    *ngIf="form.dirty && form.invalid"
    class="top-margin-15"
    color="alert-red"
  >
    <ng-container *ngIf="form.hasError('max')">
      <!-- TODO: currency -->
      A számlák összege nem haladhatja meg a
      {{ form.errors.max.max | number : "1.0" : "hu" }}
      forintot
    </ng-container>
    <ng-container *ngIf="form.hasError('required')">
      Legalább 1 számla kiválasztása kötelező!
    </ng-container>
    <!-- Form is invalid but only child controls have errors -->
    <ng-container *ngIf="form.errors === null">
      <ng-container *ngFor="let invoiceForm of form.controls">
        <div *ngIf="invoiceForm.get('file').hasError('required')">
          Kötelező feltölteni a számlaképet a
          {{ invoiceForm.value.invoice_number }} számlánál!
        </div>
      </ng-container>
    </ng-container>
  </span>
</ng-template>

<div class="flex vcenter title">
  <h2>Számlák kiválasztása</h2>
  <mat-progress-spinner
    *ngIf="invoicesLoading"
    mode="indeterminate"
    diameter="24"
  ></mat-progress-spinner>
</div>

<div *ngIf="ksziSyncInProgress" class="kszi-warning flex vcenter top-margin-10">
  <mat-progress-spinner
    mode="indeterminate"
    diameter="30"
  ></mat-progress-spinner>
  <div class="flex column">
    <span>A számlák betöltése folyamatban van.</span>
    <span>
      Várhatóan ekkora lesz mindegyik elérhető:
      {{ ksziEstimatedFinishTime | date : "yyyy.MM.dd. HH:mm" }}
    </span>
  </div>
</div>

<div *ngIf="hasMultipleInvoices" class="description top-margin-10">
  Több lejárt számlát is észrevettünk, ami indítható lenne. Válaszd ki, hogy
  melyeket szeretnéd egy ügyben most elindítani.
</div>
<section class="forms flex column">
  <!-- <ng-template [ngTemplateOutlet]="formError"></ng-template> -->
  <table
    mat-table
    matSort
    [dataSource]="dataSource"
    multiTemplateDataRows
    class="top-margin-15 mat-elevation-z1"
  >
    <ng-container matColumnDef="selected">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let element" class="alt-theme">
        <mat-checkbox
          [disabled]="invoicesLoading"
          style="pointer-events: none"
          id="invoice-checkbox-{{ element.invoice_id }}"
          [checked]="invoiceIndex.has(element.invoice_id)"
          color="accent"
        ></mat-checkbox>
      </td>
    </ng-container>
    <ng-container matColumnDef="invoice_number">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>Számla sorszáma</th>
      <td mat-cell *matCellDef="let element">
        {{ element.invoice_number }}
      </td>
    </ng-container>
    <ng-container matColumnDef="due_date_at">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>Lejárat</th>
      <td mat-cell *matCellDef="let element">
        {{ element.due_date_at | date : "yyyy.MM.dd." }}
      </td>
    </ng-container>
    <ng-container matColumnDef="remaining_amount">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>Összeg</th>
      <td mat-cell *matCellDef="let element">
        {{ element.total_remaining_amount | number : "1.0" : "hu" }}
        {{ element.currency_iso }}
      </td>
    </ng-container>

    <ng-container matColumnDef="file">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>Számlakép</th>
      <td mat-cell *matCellDef="let element">
        <ng-container
          *ngIf="!element.file && invoiceIndex.has(element.invoice_id)"
        >
          <input
            hidden
            #upload
            type="file"
            accept="
              application/msword,
              application/vnd.openxmlformats-officedocument.wordprocessingml.document,
              application/vnd.ms-powerpoint,
              application/vnd.openxmlformats-officedocument.presentationml.presentation,
              text/plain,
              application/vnd.ms-xpsdocument,
              image/jpg,
              image/jpeg,
              image/png,
              image/gif,
              image/bmp,
              image/tiff,
              image/svg+xml,
              image/vnd.adobe.photoshop,
              application/pdf,
              application/zip,
              application/x-rar-compressed,
              application/octet-stream,
              application/gzip,
              application/x-tar,
              application/vnd.ms-excel,
              application/vnd.openxmlformats-officedocument.spreadsheetml.sheet
            "
            (click)="$event.stopPropagation()"
            (change)="uploadInvoice($event, element)"
          />
          <a
            class="invalid-file"
            (click)="$event.stopPropagation(); upload.click()"
          >
            Számlakép feltöltése
          </a>
        </ng-container>
        <ng-container *ngIf="element.file">
          <a
            *ngIf="element.file.storage_url"
            (click)="$event.stopPropagation()"
            [href]="element.file.storage_url"
            target="_blank"
            class="valid-file"
          >
            Számlakép rendben
          </a>
          <div
            *ngIf="!element.file.storage_url"
            class="flex vcenter valid-file"
          >
            <span>Számlakép rendben</span>
          </div>
        </ng-container>
      </td>
    </ng-container>

    <ng-container matColumnDef="select-all-button">
      <td mat-cell *matHeaderCellDef class="alt-theme">
        <mat-checkbox
          [disabled]="invoicesLoading"
          style="pointer-events: none"
          id="invoice-checkbox-all"
          [checked]="form.length === dataSource.data.length && form.length > 0"
          color="accent"
        ></mat-checkbox>
      </td>
    </ng-container>

    <ng-container matColumnDef="select-all-label">
      <td mat-cell *matHeaderCellDef colspan="4">
        <u>Összes kijelölése</u>
      </td>
    </ng-container>

    <ng-container matColumnDef="corrections">
      <td mat-cell *matCellDef="let element" colspan="5">
        <div class="correction-title">Számla</div>
        <table class="correction-table">
          <td class="index">1.</td>
          <td class="invoice-number">{{ element.invoice_number }}</td>
          <td class="amount">
            {{ element.remaining_amount | number : "1.0" : "hu" }}
            {{ element.currency_iso }}
          </td>
        </table>
        <div class="correction-title">Helyesbítő számlák</div>
        <table class="correction-table">
          <tr *ngFor="let correction of element.corrections; let i = index">
            <td class="index">{{ i + 2 }}.</td>
            <td class="invoice-number">{{ correction.invoice_number }}</td>
            <td class="amount">
              {{ correction.remaining_amount | number : "1.0" : "hu" }}
              {{ correction.currency_iso }}
            </td>
          </tr>
        </table>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr
      mat-header-row
      class="select-row"
      *matHeaderRowDef="['select-all-button', 'select-all-label']"
      (click)="invoicesLoading ? '' : toggleAllInvoices()"
    ></tr>
    <tr
      mat-row
      class="select-row"
      *matRowDef="let row; columns: displayedColumns"
      (click)="invoicesLoading ? '' : toggleInvoice(row)"
    ></tr>
    <tr
      mat-row
      class="corrections-row"
      [class.has-corrections]="row.corrections.length > 0"
      *matRowDef="let row; columns: ['corrections']"
    ></tr>
  </table>

  <mat-paginator
    *ngIf="dataSource.data.length > 5"
    pageSize="5"
    [length]="dataSource.data.length"
    [pageSizeOptions]="[1, 5, 10]"
    disableUnderline
    showFirstLastButtons
    class="mat-elevation-z1"
  ></mat-paginator>

  <ng-template [ngTemplateOutlet]="formError"></ng-template>
</section>
