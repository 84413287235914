import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CasesService } from 'src/app/services/cases/cases.service';
import { PaymentStepForm, Summary } from 'src/app/services/form/form.resources';
import { FormService } from 'src/app/services/form/form.service';
import { StartWithTransferDialogComponent, StartWithTransferDialogData } from './start-with-transfer-dialog/start-with-transfer-dialog.component';

@Component({
  selector: 'app-summary-form',
  templateUrl: './summary-form.component.html',
  styleUrls: ['./summary-form.component.scss']
})
export class SummaryFormComponent implements OnInit, OnDestroy {
  readonly paymentMethod = this.fb.control('card');
  get form(): FormGroup { return this.formService.form.get('summary') as FormGroup; }
  get summary(): Summary { return this.formService.forms.find(({ name }) => name === 'summary')?.data as Summary; }

  get productType(): FormControl { return this.form.get('product_type') as FormControl; }
  get debtorEmail(): FormControl { return this.form.get('debtor.email') as FormControl; }
  get isStartCasePriceClaimAdded(): FormControl { return this.form.get('payee_case.is_start_case_price_claim_added') as FormControl; }
  get isInterestAdded(): FormControl { return this.form.get('payee_case.is_interest_added') as FormControl; }
  get isFlatRateCostClaimAdded(): FormControl { return this.form.get('payee_case.is_flat_rate_cost_claim_added') as FormControl; }

  get loading(): boolean { return this.formService.saving.value > 0; }

  private readonly optionalClaimForms: PaymentStepForm[] = [
    'flat_rate_cost_added_form',
    'interest_added_form',
    'start_case_price_added_form',
  ];

  get showProductForms(): boolean { return this.summary.product.is_product_type_editable; }
  get productForms(): PaymentStepForm[] { return this.summary.product.forms[this.summary.product.type]; }
  get showOptionalClaimsForm(): boolean { return this.productForms.some(form => this.optionalClaimForms.includes(form)); };
  get showDebtorForm(): boolean { return this.productForms.includes('debtor_form'); };
  get showFlatRateCostAddedForm(): boolean {
    return this.summary.payee_case.has_claims_eligible_for_flat_rate_cost
      && this.productForms.includes('flat_rate_cost_added_form');
  };
  get showInterestAddedForm(): boolean { return this.productForms.includes('interest_added_form'); };
  get showStartCasePriceAddedForm(): boolean { return this.productForms.includes('start_case_price_added_form'); };

  get interestAmountLabel(): string {
    const amount = this.summary.payee_case.interest_amount.toLocaleString('hu-HU');
    return `Törvényes kamatok (${amount} HUF)`;
  }

  get euroLabel(): string {
    const hufAmount = this.summary.payee_case.flat_rate_cost_claims_sum.toLocaleString('hu-HU');
    return `Törvény szerinti 40 EUR behajtási költségátalány (${hufAmount} HUF)`;
  }

  // @todo better label
  get startCaseFeeLabel(): string {
    const amount = this.summary.fees.start
      .find(fee => fee.label === 'FMH ügyindítási díj')?.amount
      .toLocaleString('hu-HU');
    return `FMH ügyindítási díj (${amount} HUF)`;
  }
  get startCaseFeeAmount(): number {
    return this.summary.fees.start.reduce((sum, fee) => sum + fee.amount, 0);
  }
  get successCaseFeeAmount(): number {
    return this.summary.fees['closed-success'].reduce((sum, fee) => sum + fee.amount, 0);
  }

  // @todo better label
  get administrativeFeeLabel(): string {
    const amount = this.summary.fees.start
      .find(fee => fee.label === 'FMH közjegyzői díj')?.amount
      .toLocaleString('hu-HU');
    return `FMH közjegyzői díj (${amount} HUF)`;
  }

  private readonly destroy = new Subject<void>();

  hasUnsavedChanges = false;

  constructor(
    private formService: FormService,
    private fb: FormBuilder,
    private dialog: MatDialog,
    private router: Router,
    private casesService: CasesService,
  ) {
  }

  async ngOnInit() {
    this.formService.saving
      .pipe(takeUntil(this.destroy))
      .subscribe({
        next: value => {
          if (value <= 0) {
            this.hasUnsavedChanges = false;
          }
        }
      });

    this.form.valueChanges
      .pipe(
        takeUntil(this.destroy),
      )
      .subscribe({
        next: () => {
          this.hasUnsavedChanges = true;
        }
      });
  }

  ngOnDestroy(): void {
    this.destroy.next();
    this.destroy.complete();
  }

  onInterestToggle(event: boolean): void {
    this.isInterestAdded.patchValue(event);
  }

  onFlatRateCostClaimToggle(event: boolean): void {
    this.isFlatRateCostClaimAdded.patchValue(event);
  }

  async startInstantly(): Promise<void> {
    const result = await this.casesService.startSoftSuccessCase(this.formService.case.id);
    this.router.navigate(['/user/cases'], {
      queryParams: {
        filterType: 'payeeId',
        filter: result.payee_case_reference_id,
      },
    });
  }

  async startCaseWithPayment(): Promise<void> {
    if (this.paymentMethod.value === 'card') {
      try {
        await this.formService.startWithCard();
      } catch (error) {
        console.error('Error while starting with card', error);
      }
    } else if (this.paymentMethod.value === 'transfer') {
      try {
        await this.formService.startWithTransfer();
        this.openCaseStartSuccessDialog();
      } catch (error) {
        console.error('Error while starting with transfer', error);
      }
    }
  }

  private openCaseStartSuccessDialog() {
    const data: StartWithTransferDialogData = {
      currency_iso: 'HUF',
      payee_case_reference_id: this.formService.case.payee_case_reference_id,
      transfer_amount: this.startCaseFeeAmount,
    };
    const dialogRef = this.dialog.open(StartWithTransferDialogComponent, {
      data,
      position: {
        top: '50px',
      },
      autoFocus: false,
    });

    dialogRef.afterClosed().subscribe(async () => {
      await this.router.navigate(['/user/cases'], {
        queryParams: {
          filterType: 'payeeId',
          filter: this.formService.case.payee_case_reference_id,
        },
      });
    });
  }
}
