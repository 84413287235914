import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { RouterModule } from '@angular/router';
import { FooterModule } from 'src/app/shared/footer/footer.module';
import { PayeeAutosaveModule } from 'src/app/shared/payee-autosave/payee-autosave.module';
import { PayeeButtonsModule } from 'src/app/shared/payee-buttons/payee-buttons.module';
import { PayeeIconModule } from 'src/app/shared/payee-icon/payee-icon.module';
import { CaseFormComponent } from './case-form.component';
import { FormHeaderComponent } from './form-header/form-header.component';
import { BankAccountFormModule } from './forms/bank-account-form/bank-account-form.module';
import { ClaimsFormModule } from './forms/claims-form/claims-form.module';
import { ClientFormModule } from './forms/client-form/client-form.module';
import { DebtorFormModule } from './forms/debtor-form/debtor-form.module';
import { SummaryFormModule } from './forms/summary-form/summary-form.module';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { UserEmailFormModule } from './forms/user-email-form/user-email-form.module';
import { SzamlazzhuInvoicesFormModule } from './forms/szamlazzhu-invoices-form/szamlazzhu-invoices-form.module';
import { LoadingScreenModule } from 'src/app/shared/loading-screen/loading-screen.module';



@NgModule({
  declarations: [
    CaseFormComponent,
    FormHeaderComponent,
  ],
  imports: [
    LoadingScreenModule,
    BankAccountFormModule,
    ClaimsFormModule,
    ClientFormModule,
    CommonModule,
    DebtorFormModule,
    FooterModule,
    MatButtonModule,
    MatProgressSpinnerModule,
    PayeeAutosaveModule,
    PayeeButtonsModule,
    PayeeIconModule,
    RouterModule,
    SummaryFormModule,
    SzamlazzhuInvoicesFormModule,
    UserEmailFormModule,
  ]
})
export class CaseFormModule { }
