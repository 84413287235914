import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SzamlazzhuInvoicesFormComponent } from './szamlazzhu-invoices-form.component';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { PayeeButtonsModule } from 'src/app/shared/payee-buttons/payee-buttons.module';
import { PayeeIconModule } from 'src/app/shared/payee-icon/payee-icon.module';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';



@NgModule({
  declarations: [
    SzamlazzhuInvoicesFormComponent,
  ],
  imports: [
    CommonModule,
    MatPaginatorModule,
    MatProgressSpinnerModule,
    MatTableModule,
    MatSortModule,
    MatCheckboxModule,
    PayeeButtonsModule,
    PayeeIconModule,
  ]
})
export class SzamlazzhuInvoicesFormModule { }
