<h2>Összegzés</h2>
<section class="summary-data top-margin-20">
  <!-- Client -->
  <div class="flex vcenter">
    <div class="grow1 summary-label">Hitelező</div>
    <div class="summary-value">{{ summary.client.name }}</div>
  </div>
  <!-- Debtor -->
  <div class="flex vcenter">
    <div class="grow1 summary-label">Adós</div>
    <div class="summary-value">{{ summary.debtor.name }}</div>
  </div>
  <!-- Claims -->
  <div>
    <div class="flex vcenter">
      <div class="grow1 summary-label">Követelések</div>
      <div class="summary-label">
        {{
          summary.payee_case.displayed_claims_sum_original_amount
            | number : "1.0" : "hu"
        }}
        {{ summary.payee_case.currency_iso }}
      </div>
    </div>
    <div class="top-margin-5">
      <div
        *ngFor="let claim of summary.claims; let i = index"
        class="flex vcenter"
      >
        <div class="grow1 claim-item">
          {{ i + 1 }}. követelés (Lejárt
          {{ claim.due_date_at | date : "yyyy.MM.dd." }})
        </div>
        <div class="claim-item">
          {{ claim.original_amount | number : "1.0" : "hu" }}
          {{ claim.currency_iso }}
        </div>
      </div>
    </div>
  </div>
  <!-- Optional Claims -->
  <div *ngIf="showOptionalClaimsForm" class="optional-claims">
    <div class="optional-claim-title flex vcenter">
      <payee-icon name="plus-black" width="15" height="15"></payee-icon>
      &nbsp;Opcionális követelések
    </div>
    <payee-toggle
      *ngIf="showInterestAddedForm"
      [disabled]="loading"
      [selected]="isInterestAdded.value"
      [text]="interestAmountLabel"
      (selectionChanged)="onInterestToggle($event)"
      matTooltip="A teljes követelés megfizetéséig felhalmozódó kamatok érvényesítése"
      matTooltipPosition="above"
      matTooltipClass="custom-tooltip"
      direction="top"
    ></payee-toggle>
    <payee-toggle
      *ngIf="showFlatRateCostAddedForm"
      [selected]="isFlatRateCostClaimAdded.value"
      [disabled]="loading"
      [text]="euroLabel"
      (selectionChanged)="onFlatRateCostClaimToggle($event)"
      matTooltip="Az egy éven belül lejárt követelésekre követelésenként
        40 EUR-nak megfelelő összeg érvényesítése"
      matTooltipPosition="above"
      matTooltipClass="custom-tooltip"
    ></payee-toggle>
    <payee-toggle
      *ngIf="isStartCasePriceClaimAdded.value"
      [selected]="true"
      [disabled]="true"
      text="Hozzáadom az ügyindítási díjat a követelésekhez ({{
        startCaseFeeAmount | number : '1.0' : 'hu'
      }} HUF)"
      matTooltip="Fizetési meghagyás esetén automatikusan az ügyindítási díj
    is hozzáadódik a követelésedhez, amit az adósnak kell megfizetnie."
      matTooltipPosition="above"
      matTooltipClass="custom-tooltip"
    ></payee-toggle>
  </div>
  <!-- All Claims -->
  <div class="all-claims">
    <div class="flex vcenter">
      <div class="summary-label grow1">Teljes követelés</div>
      <div class="summary-label">
        {{
          summary.payee_case.claims_sum_original_amount | number : "1.0" : "hu"
        }}
        {{ summary.payee_case.currency_iso }}
      </div>
    </div>
    <div class="all-claims-description">
      {{ summary.debtor.name }} adósnak összesen ennyit kell megfizetnie
    </div>
  </div>
</section>
<h2 class="top-margin-30">Választott lépés és árazás</h2>
<div *ngIf="showProductForms" class="payment-method-description">
  <span> Ha nem tudja, melyikre van szüksége, kattintson ide </span>
  <a
    href="https://help.payee.tech/hu/articles/6423716-melyik-modult-valasszam"
    target="_blank"
  >
    bővebb információkért
  </a>
</div>
<!-- For some reason binding the control directly causes change after check NG0100 errors -->
<mat-radio-group
  [value]="productType.value"
  (change)="productType.patchValue($event.value)"
  [disabled]="productType.disabled"
  class="product-type-selector flex column alt-theme top-margin-20"
  color="primary"
>
  <mat-radio-button
    *ngIf="showProductForms || productType.value === 'notice'"
    value="notice"
  >
    <div class="product-type-title">Fizetési felszólítás</div>
    <div class="product-type-description">
      Felszólítjuk az adóst, hogy a fizetési oldalon tekintse át és fizesse meg
      a követelést
    </div>
  </mat-radio-button>
  <mat-radio-button
    *ngIf="showProductForms || productType.value === 'demand_letter'"
    value="demand_letter"
  >
    <div class="product-type-title">Ügyvédi levél</div>
    <div class="product-type-description">
      Ügyvédi postai levélben hívjuk fel az adóst a követelés megfizetésére
    </div>
  </mat-radio-button>
  <mat-radio-button
    *ngIf="showProductForms || productType.value === 'hard_1'"
    value="hard_1"
  >
    <div class="flex vcenter hard-radio-wrapper">
      <div>
        <div class="product-type-title">Fizetési meghagyás és végrehajtás</div>
        <div class="product-type-description">
          Hivatalos eljárásban hívja fel a közjegyző az adóst a fizetésre
        </div>
      </div>
      <a
        href="https://help.payee.tech/hu/articles/6423716-melyik-modult-valasszam"
        target="_blank"
        class="flex vcenter hard-recommended-box alt-theme"
        matTooltipClass="custom-tooltip"
        matTooltipPosition="above"
        matTooltip="
          A fizetési meghagyás jogerőre emelkedésekor a végrehajtás online elindítható,
          díja {{
          summary.product.hard_2_price.amount | number : '1.0' : 'hu'
        }} Ft + ÁFA (+ 1% illeték, min. {{
          summary.product.hard_2_price.min | number : '1.0' : 'hu'
        }} Ft, max. {{
          summary.product.hard_2_price.max | number : '1.0' : 'hu'
        }} Ft).
          Bővebb információ arról, hogy mikor indítható végrehajtás:
        "
      >
        <payee-icon name="info-grey-outline"></payee-icon>
        <span class="recommended-chip">Ajánlott</span>
      </a>
    </div>
  </mat-radio-button>
</mat-radio-group>
<div class="fees top-margin-20">
  <div *ngIf="startCaseFeeAmount > 0" class="flex vcenter bottom-margin-5">
    <div class="grow1 start-fee-title">Fizetendő ügyindítási díj</div>
    <div class="start-fee-title">
      {{ startCaseFeeAmount | number : "1.0" : "hu" }} HUF
    </div>
  </div>
  <div *ngIf="successCaseFeeAmount > 0" class="flex vcenter bottom-margin-5">
    <div class="grow1 start-fee-title">Sikerdíj</div>
    <div class="start-fee-title">
      {{ successCaseFeeAmount | number : "1.0" : "hu" }} HUF
    </div>
  </div>
  <ng-container *ngFor="let fees of summary.fees | keyvalue">
    <div *ngFor="let fee of fees.value" class="flex vcenter">
      <div class="grow1 fee-text">
        {{ fee.label }}
      </div>
      <span class="fee-detail">{{ fee.details_text }}&nbsp; </span>
      <span class="fee-text">
        {{ fee.amount | number : "1.0" : "hu" }}
        HUF
      </span>
    </div>
  </ng-container>
</div>

<ng-container *ngIf="showDebtorForm">
  <h2 class="top-margin-20">Adós email címe</h2>
  <div class="debtor-email-description top-margin-10">
    Erre az email címre fogjuk kiküldeni a fizetési felszólítás sorozatot
  </div>
  <div class="top-margin-20">
    <payee-form-field width="widest" height="tall">
      <input
        payee-input
        placeholder="Adós email címe"
        [formControl]="debtorEmail"
      />
    </payee-form-field>
  </div>
</ng-container>

<ng-container *ngIf="startCaseFeeAmount > 0">
  <h2 class="top-margin-30">Válasszon fizetési módot</h2>
  <mat-radio-group
    [formControl]="paymentMethod"
    class="payment-method-selector flex column alt-theme top-margin-20"
    color="primary"
  >
    <mat-radio-button value="card">
      <div class="flex vcenter">
        <div class="flex column grow1">
          <div class="payment-method-title">Bankkártyával fizetek</div>
          <div class="payment-method-description">Biztonságos és gyors</div>
        </div>
        <img
          src="assets/shared/global-images/partner-logos/barion.svg"
          width="151px"
          alt="barion"
        />
      </div>
    </mat-radio-button>
    <mat-radio-button value="transfer">
      <div class="payment-method-title">Átutalással fizetek (Díjbekérő)</div>
      <div class="payment-method-description">48 óra átfutási idő</div>
    </mat-radio-button>
  </mat-radio-group>

  <button
    [disabled]="form.invalid || loading || hasUnsavedChanges"
    (click)="startCaseWithPayment()"
    payee-button
    type="button"
    class="top-margin-25"
    width="widest"
    height="tall"
  >
    <span *ngIf="paymentMethod.value === 'card'">
      {{ startCaseFeeAmount | number : "1.0" : "hu" }} HUF fizetése
      bankkártyával
    </span>
    <span *ngIf="paymentMethod.value === 'transfer'">
      {{ startCaseFeeAmount | number : "1.0" : "hu" }} HUF összegről díjbekérőt
      kérek
    </span>
  </button>
  <div class="payment-disclaimer top-margin-20">
    A gomb megnyomásával még nem fizet, hanem átírányítjuk egy fizetési
    felületre. A fizetést a Legal Labs Kft. dolgozza fel, és a tranzakcióról
    elektronikus számlát küldünk a fizetése után emailben.
  </div>
</ng-container>

<ng-container *ngIf="startCaseFeeAmount === 0">
  <button
    [disabled]="form.invalid || loading || hasUnsavedChanges"
    (click)="startInstantly()"
    payee-button
    type="button"
    class="top-margin-25"
    width="widest"
    height="tall"
  >
    Rendben és indítás most
  </button>
  <div class="payment-disclaimer top-margin-20">
    A gomb megnyomásával elindul a fizetési felszólítási folyamat. Önnek
    kizárólag akkor kell sikerdíjat fizetnie, az adós bankkártyával rendezi a
    tartozását a Payee felületén. Ekkor azonnal számlát küldünk a sikerdíjról,
    és átutaljuk a sikerdíjjal csökkentett összeget.
  </div>
</ng-container>
